.form {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.form > * {
  margin: 0;
}

.fullWidth {
  width: 100%;
}

.uploadWrapper {
  grid-column: 1 / -1;
}

.uploadWrapper p {
  font-size: 0.75rem;
  color: var(--neutral-gray);
}
