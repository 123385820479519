.overview {
  composes: section from './WarrantyPage.module.css';
  display: grid;
  gap: 0 24px;
  grid-template-columns: minmax(100px, max-content) minmax(100px, max-content);
  justify-content: space-around;
}

.image {
  max-width: 250px;
}

:global(.ant-warranty-image),
.qr {
  margin-bottom: 10px;
}

.overview h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

@media (max-width: 426px) {
  .overview h3 {
    font-size: 1.25em;
  }
}

.fields {
  display: contents;
}

.field {
  display: inline-block;
}

.field:empty::after {
  content: '----';
}

.image,
.overview h3 {
  grid-column: 1;
}

.qr,
.activationCode {
  grid-column: 2;
}

.qr {
  grid-row: 1;
  align-self: self-end;
  width: 100%;
  height: auto;
  max-width: 164px;
  padding: 7px;
  border-radius: 12px;
  background-color: #edf0f4;
}

.activationCode {
  grid-row: 2;
}

.btn {
  grid-column: 1 / -1;
  margin-top: 1.5em;
  justify-self: center;
}

.resultEndDate {
  font-weight: 600;
  color: var(--primary-color);
}

@media (max-width: 579.98px) {
  .fieldWrapper {
    display: flex;
    flex-direction: column;
  }

  .btn {
    justify-self: stretch;
  }
}
