.warrantyPage {
  color: var(--neutral-gray);
}

@media (max-width: 426px) {
  .warrantyPage {
    font-size: 0.875rem;
  }
}

.warrantyPage main {
  max-width: 819px;
  padding: 0 var(--page-padding-x);
  margin: 44px auto 106px auto;
}

/* Shared components */
.border {
  border-bottom: 1px solid var(--gray-3);
}

.section {
  composes: border;
  padding-bottom: 32px;
  margin-top: 32px;
}

.section:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.section h2 {
  margin-bottom: 1.5rem;
  font-size: 1.375em;
  font-weight: 600;
}

.label {
  color: black;
  font-weight: 600;
}
