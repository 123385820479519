.alert {
  border-radius: 9999px;
  background-color: var(--primary-color-light);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 12px 24px;
  width: fit-content;
  margin-bottom: 24px;
}

.alert svg {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.descriptions th {
  color: black;
  text-align: start;
  width: 50%;
}

@media (min-width: 576px) {
  .descriptions th {
    width: 33.33%;
  }
}

.descriptions table {
  width: 100%;
}

.descriptions tr:nth-child(even) {
  border-bottom: 1px solid var(--gray-3);
}

.descriptions tbody {
  vertical-align: top;
}

.descriptions :is(th, td) {
  padding: 0 5px;
}

.descriptions :is(th, td):first-child {
  padding-left: 0;
}

.descriptions :is(th, td):last-child {
  padding-right: 0;
}

.descriptions th {
  padding-top: 12px;
  padding-bottom: 10px;
}

.descriptions td {
  padding-bottom: 12px;
}

.descriptions td.warrantyPeriod > div:not(:empty),
.descriptions td.daysLeft > div:not(:empty) {
  color: var(--primary-color);
}

.descriptions td > div:empty::after {
  content: '----';
}

.descriptions tr:first-child th {
  padding-top: 0;
}

.descriptions tr:last-child {
  border-bottom: none;
}
