.successBtn {
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background: #f3fff7;
  color: var(--success-color);
  font-weight: 600;
  white-space: nowrap;
}
