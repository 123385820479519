.topNavigation {
  background-color: var(--primary-color-light);
  display: grid;
  justify-content: center;
  justify-items: start;
  grid-template-columns: minmax(0, var(--max-page-width));
}

.topNavigation a {
  padding: 12.17px var(--page-padding-x) 15.29px var(--page-padding-x);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
