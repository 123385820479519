.productPreview {
  padding: 16px 18px;
  display: grid;
  gap: 12px 14.39px;
  grid-template-columns: auto 1fr;
  background-color: var(--primary-color-light);
  border: 1px solid var(--gray-4);
  border-radius: var(--border-radius-base);
  margin-bottom: 24px;
}

.productPreview h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.productPreview p {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em 30.88px;
}

.image {
  grid-row: span 2;
  max-width: 79.16px;
  align-self: flex-start;
}
