.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #219737;
}
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(94, 106, 117, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #e6e9ec;
  cursor: pointer;
}
.disabled-row {
  opacity: 50%;
}

.anticon.ant-notification-notice-icon-warning {
  color: #f31d00 !important;
}
.app-content {
  margin-top: -18px !important;
  /* padding: 0 25px !important; */
}
.ant-drawer-mask {
  background: none !important;
}
.ant-drawer {
  left: 259px !important;
}
.ant-drawer-content {
  background: #fafafb;
}

@media only screen and (max-width: 768px) {
  .app-content {
    padding: 0 !important;
  }
}

.is-sticky {
  position: sticky;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.ant-badge-status-dot {
  width: 12px !important;
  height: 12px !important;
}
