.grid {
  flex: 1;
  width: 100%;
  padding: 24px var(--page-padding-x);
  margin: 0 auto;
  max-width: 1100px;
  display: grid;
  justify-items: center;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.gridItem {
  font-size: 0.875rem;
  display: grid;
  width: 100%;
  max-width: 250px;
  gap: 16px 10px;
  color: var(--neutral-gray);
}

@media (max-width: 475px) {
  .grid {
    gap: 60px;
  }

  .gridItem {
    max-width: revert;
    grid-template-columns: 42% 1fr;
    row-gap: 16px;
  }
}

.productName {
  color: #082f53;
  font-weight: 600;
}

.image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: var(--border-radius-base);
}

.btn {
  grid-column: 1 / -1;
  width: 100%;
  align-self: self-end;
}

.avoidWrap {
  display: inline-block;
  line-height: 1.5;
}

.avoidWrap:empty::after {
  content: '----';
}
