.table {
  text-align: left;
}

.table th,
.table td {
  vertical-align: top;
  padding: 0;
}

.table td:empty::after {
  content: '----';
}

.table tr:not(:last-child) th {
  padding-bottom: 16px;
}

.table th {
  color: black;
  padding-right: 44px;
}
