.table th,
.table td {
  white-space: nowrap;
  max-width: 20ch;
  text-align: start;
}

.detailsButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  background: none;
  transition: none;
}

.detailsButton:hover {
  color: var(--primary-color);
}

.detailsButton:active {
  color: var(--primary-color-active);
}

.detailsTable tbody {
  vertical-align: top;
}

.detailsTable tr:not(:last-child) th {
  padding-bottom: 16px;
}

.detailsTable th {
  text-align: start;
  padding-right: 48px;
}

.detailsTable td:empty::after {
  content: '----';
}

.payment {
  color: var(--error-color);
}
