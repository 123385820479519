.landingPage {
  background: repeat-x top center;
}

.landingPage h1 {
  margin-top: 78px;
  margin-bottom: 0;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 60px;
  font-weight: 700;
}

.landingPage h2 {
  margin-top: 16px;
  margin-bottom: 0;
  height: 42px;
  color: white;
  font-size: 18px;
}

.landingPage main {
  max-width: var(--max-page-width);
  margin: 0 auto;
  padding: 0.05px var(--page-padding-x);
  text-align: center;
}

.searchWrapper {
  max-width: 800px;
  border-radius: 10px;
  margin: 48px auto auto;
  padding: 24px 76px 32px 76px;
  display: flex;
  gap: 17px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(113deg, rgba(255, 255, 255, 0.47), rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(30px);
  position: relative;
}

.searchWrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: inherit;
  border: 1.86px solid transparent;
  background: radial-gradient(farthest-side at top left, rgba(255, 255, 255, 0.7), transparent)
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

@media (max-width: 565.98px) {
  .searchWrapper {
    padding-inline: 24px;
  }
}

@media (min-width: 426px) {
  .searchWrapper {
    box-shadow: 16px 32px 32px rgba(126, 138, 253, 0.08);
  }
}

.searchBox {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 4px 4px 16px;
  border-radius: 9999px;
  background-color: white;
}

.searchBox > svg {
  flex-shrink: 0;
}

.input {
  padding: 0;
  font-size: 16px;
}

.qrButton {
  display: flex !important;
  width: 100%;
  max-width: 343px;
  display: flex;
  gap: 8px;
  justify-content: center;
}

.decorator {
  margin-top: 24px;
}

.features {
  margin-top: 164px;
  margin-bottom: 74px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  color: var(--neutral-gray);
}

.feature {
  display: grid;
  align-content: start;
  gap: 12px 16px;
  text-align: start;
}

.iconWrapper {
  grid-row: span 2;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: var(--primary-color);
  color: white;
}

.featureBrief {
  grid-column: 2;
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
}

@media (min-width: 768px) {
  .landingPage {
    background-image: url('../../../../assets/svg/landing-page-background-1920.svg');
  }
}

@media (min-width: 375px) and (max-width: 767.98px) {
  .landingPage {
    background-image: url('../../../../assets/svg/landing-page-background-768.svg');
  }
}

@media (max-width: 374.98px) {
  .landingPage {
    background-image: url('../../../../assets/svg/landing-page-background-375.svg');
  }
}

@media (max-width: 383px) {
  .landingPage h1 {
    font-size: 40px;
  }
}

@media (max-width: 425.98px) {
  .landingPage h1 {
    font-size: 40px;
  }

  .searchBox {
    padding: 16px;
  }

  .searchBox input {
    order: -1;
  }

  .searchBox button {
    display: none;
  }

  .qrButton {
    width: revert;
  }

  .features {
    margin-top: 90px;
  }
}
